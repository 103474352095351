<template>
  <div class="paysuccess" id="agentTitle">
    <Card class="wbox">
      <div class="paybj successbox">
        <img
          class="payicon"
          src="http://cdn1.zhiyileiju.cn/paysuccess_icon.png"
          alt=""
        />
        <span class="payitp">支付成功</span>
        <span class="getre">请前往公众号查看“起名结果”</span>
        <van-button
          class="getbtn"
          icon="http://cdn1.zhiyileiju.cn/paysuccess_point.png"
          color="#04C967"
          round
          @click="look"
          >{{text_}}</van-button
        >
        <p style="color:#FD0101;margin-top:-8px">点击上方按钮进入公众号</p>
      </div>
    </Card>

    <div class="qimigninfo">起名信息</div>
    <Card class="wbox">
      <div class="paybj">
        <ul class="infolist">
          <li>
            <div>姓氏：{{ orderinfo.xname }}</div>
            <div style="margin-left:80px">性别：{{ orderinfo.sex }}</div>
          </li>
          <li>
            <div>
              出生日期：{{ orderinfo.bDate[0] }}年{{ orderinfo.bDate[1] }}月{{
                orderinfo.bDate[2]
              }}日{{ orderinfo.bDate[3] }}时
            </div>
          </li>
          <li>
            <div v-if="tel">手机号码：{{ tel }}</div>
          </li>
        </ul>
      </div>
    </Card>
    <!-- <Card class="wbox memadd qrbox">
      <span class="payitp">✌️或扫码关注公众号✌️</span>
      <img
        class="qrcode"
        src="http://cdn1.zhiyileiju.cn/qrcode_for_gh_b6.jpeg"
        alt=""
      />
      <span class="ttitp">长按保存二维码（或截图保存）</span>
      <span class="ttitp">在微信内打开扫一扫，选择相册中本图即可关注</span>
    </Card> -->
    <div class="qimigninfo">温馨提示</div>
    <div class="wbox " style="text-align: center;">
    <div class="center" v-if="orderinfo.fromk !== 'tk2'">
		  <img style="width: 100%;margin-top: 20px;" src="https://img.nnabc.cn/22971647848525_.pic_hd.jpg" >
	  </div>
      <!-- <div class="paybj">
        <div class="kefubox">
          <div>
            <div class="item">
              如有疑问，可添加客服微信:<span>sunwei13844768688</span>
            </div>
            <div class="item">上班时间:<span>早上10点到晚上18点30分</span></div>
          </div>
          <van-button
            color="#04C967"
            class="fz"
            @click="copy('sunwei13844768688')"
            size="small"
            >复制</van-button
          >
        </div>
      </div> -->
    </div>

    <!-- <van-dialog
      v-model="showPayDialog"
      :showCancelButton="false"
      :showConfirmButton="false"
      :width="dialogwidth"
    >
      <div class=" dialogbox">
        <div class="tip">输入手机号查询起名结果</div>
        <div class="center formb">
          <van-field
            size="large"
            input-align="center"
            v-model="tel"
            autofocus
            placeholder="请输入手机号，领取取名结果"
            type="tel"
          />
        </div>
        <div class="center agreetip">
          <em class="agree"></em>
          个人隐私保护协议
        </div>
        <div class="center" style="margin-top:20px">
          <van-button color="#b95353" style="width:280px" block round @click="subtel"
            >领取结果</van-button
          >
        </div>
      </div>
    </van-dialog> -->
    <div class="wrapper">
      <textarea readonly id="input"></textarea>
    </div>
    <div class="shade_box_" v-if="is_popup" @click="closePopupButton"></div>
        <div class="shade_box_content_box_" v-if="is_popup">
            <Card class="wbox">
              <div class="paybj successbox">
                <img
                  class="payicon"
                  src="http://cdn1.zhiyileiju.cn/paysuccess_icon.png"
                  alt=""
                />
                <span class="payitp">支付成功</span>
                <span class="getre">请前往公众号查看“起名结果”</span>
                <van-button
                  class="getbtn"
                  icon="http://cdn1.zhiyileiju.cn/paysuccess_point.png"
                  color="#04C967"
                  round
                  @click="look"
                  >{{text_}}</van-button
                >
                <p style="color:#FD0101;margin-top:-8px">点击上方按钮进入公众号</p>
                <!-- <span class="btntip">如快速进入失败，仍可按下方教程查看结果</span> -->
              </div>
            </Card>
             <div class="close_buttom_" @click="closePopupButton">
                <img src="http://cdn1.zhiyileiju.cn/WechatIMG2047.png" alt="">
            </div>
        </div>
        <SucceedPhoneBindingV2 @phonePopupSubmit="look" />
  </div>
</template>

<script>
import axios from "../utils/request";
import { Dialog } from "vant";
import solarLunar from "../components/datepick/solarlunar";
import SucceedPhoneBindingV2 from "../components/SucceedPhoneBindingV2/index.vue";
import { Field } from "vant";
import { Toast } from "vant";
import report from "../utils/stat";

export default {
  components: { vanField: Field,SucceedPhoneBindingV2 },
  name: "paysuccess",
  data() {
    return {
      enterpriseWeChat:'',
      is_popup:true,
      text_ :'点击快速进入公众号领结果',
      isfal:false,
      tel: "",
      price:'',
      OfficialAccounts:'weixin://dl/business/?t=YVy1AyJBhFr', //公众号
      orderinfo: {
        bDate: [2021],
        nongli: {},
        eightChara: {
          bazi: [{ y: "辛丑" }, { m: "乙未" }, { d: "甲子" }, { h: "甲子" }],
          nayin: [
            { y: "壁上土" },
            { m: "沙中金" },
            { d: "海中金" },
            { h: "海中金" },
          ],
        },
        askTao: {
          qk_score_ratio: {
            金: "6%",
            木: "11%",
            水: "25%",
            火: "12%",
            土: "46%",
          },
        },
      },
      orderid: this.$route.params.id,
      showPayDialog: false,
      id: this.$route.params.id,
    };
  },
  computed: {
    dialogwidth() {
      let width = window.innerWidth * 0.9;
      if (width > 540) width = 540;
      return width;
    },
  },
  async created() {
    let price = await axios.get("/getSalesPrice");
    let from = this.$route.query.from || 10;
    this.price = price.data.data.filter((item) => item.fromk == from)[0];
    try {
      let OfficialAccounts_data = await axios.get("/getXcxUrl/"+this.$route.params.id);
      OfficialAccounts_data.data.data.url === null ? '' : this.OfficialAccounts = OfficialAccounts_data.data.data.url
    } catch (error) {
      console.log('error',error)
    }
    // console.log('OfficialAccounts_data',OfficialAccounts_data.data.data.url)
    // this.price.xcxurl === null ? '' : this.OfficialAccounts = this.price.xcxurl
    // console.log('-=-===')
    let showAnswer_fla = await axios.get(`/showAnswer/0`);
    this.isfal = showAnswer_fla.data.data.show_answer
    if( this.isfal === false ){
        this.text_ = '点击快速进入公众号领结果'
    }else{
        this.text_ = '立即领取起名结果'
    }
    let EnterpriseMicro = await axios.get(`/wechatqy/getKfUrl/${this.$route.params.id}`);
    this.enterpriseWeChat = EnterpriseMicro.data.data.url;
    // console.log('EnterpriseMicro',EnterpriseMicro.data.data.url)
    await this.getdata();
    document
      .getElementById("agentTitle")
      .scrollIntoView({ block: "start", behavior: "smooth" });
      report("v1_paysuccess");

    if(!this.orderinfo.discount_amount){
        let fromk = this.$route.query.from;
        // let needCallback = ['',''];
        // let callbackId ={
        // }
        
        // if(needCallback.indexOf(fromk) > -1){
        //   _ks_trace.push({event: 'form', convertId: callbackId[fromk], cb: function(){ }})
        // }
        
        if (fromk == 'w1') {
          gdt('track', 'COMPLETE_ORDER', {'key1': 'value1',});// 下单
        }

        // if(['a1','a2','a3','a4','a5','a6','a7','a8','a9'].indexOf(fromk) > -1){
        //       _ks_trace.push({event: 'form', convertId: 307780, cb: function(){ }})
        // }
    }else{
        // let fromk = this.$route.query.from;
        // let needCallback = [];
        // let callbackId ={
        // }

        // if(needCallback.indexOf(fromk) > -1 && Math.random() < 0.25 ){
        //     _ks_trace.push({event: 'form', convertId: callbackId[fromk], cb: function(){ }})
        // }
    }


  },
  async beforeCreate() {},
  methods: {
    async getdata() {
      let orderinfo = await axios.get(`/order/${this.orderid}`);
      this.orderinfo = orderinfo.data.data;

      let birth = this.orderinfo.pub_birth_date
        ? this.orderinfo.pub_birth_date
        : this.orderinfo.pub_prebirth_date;
      let fdate = birth.split(" ");
      this.orderinfo.bDate = [...fdate[0].split("-"), ...fdate[1].split(":")];
      this.orderinfo.nongli = solarLunar.solar2lunar(
        this.orderinfo.bDate[0],
        this.orderinfo.bDate[1],
        this.orderinfo.bDate[2]
      );

      if (!this.orderinfo.bind_iphone) {
        this.showPayDialog = true;
      }
    },
    isPopupShowCallback(){ //是否显示弹窗
            this.is_popup = true;
    },
    closePopupButton(){//关闭弹窗
            this.is_popup = false;
    },
    copy(text) {
      report("v1_paysuccess_copy");
      var input = document.getElementById("input");
      input.innerText = text;
      input.select();
      document.execCommand("copy");
      Toast.success("复制成功");

    },
    openweixin() {
      report("v1_paysuccess_openMiniApp");
      location.href = "weixin://";
    },
    look() {
      report("v1_paysuccess_openwechat");
      if( this.isfal === false ){
        
        // location.href = this.OfficialAccounts;
        location.href = this.enterpriseWeChat;
      }else{
        // location.href = this.enterpriseWeChat;
        window.location.replace(`/result/${this.$route.params.id}?from=${this.$route.query.from}`)

      }
      
    },
    async subtel() {
      let val = this.tel;
      let myreg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(val) || val == "") {
        Dialog({ message: "手机号填写不正确" });
        return;
      }
      try {
        let data = await axios.post("/order/bindIphone", {
          order_id: this.id,
          bind_iphone: val,
        });
        if (data.data.message == "操作成功") {
          Toast.success("绑定成功");

          this.showPayDialog = false;
        }
      } catch (err) {
        Toast.fail("绑定失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialogbox {
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
}
.agree {
  background: url("http://cdn1.zhiyileiju.cn/yes_icon.png") no-repeat center;
  background-color: #b95353;
  background-size: 12px;
  display: inline-block;
  border-radius: 2px;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.agreetip {
  margin-top: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #525252;
}

.formb {
  border: 1px solid #979797;
  border-radius: 3px;
  width: calc(100% - 60px);
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tip {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  text-align: center;
  color: #b95353;
  margin: 25px auto;
  margin-bottom: 20px;
}

 #input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}

.dialogheader {
  height: 70px;
  background: #d6d5d5;

  font-size: 18pxpx;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #525252;
}

.wbox {
  margin: 15px;
  margin-bottom: 0;
}
.successbox {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
  .payicon {
    width: 70px;
    height: 70px;
    margin-top: 30px;
  }
  .payitp {
    font-size: 20px;
    font-weight: 400;
    color: #020202;
    margin-top: 15px;
  }
  .getre {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #fd0101;
    margin-top: 20px;
  }
  .getbtn {
    margin: 20px;
    width: 280px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    animation: scaleDrew 2s ease-in-out infinite;
  }

  .btntip {
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 400;
    color: #020202;
  }
}
.paybj {
  background: url("http://cdn1.zhiyileiju.cn/5d5057941af5857bf368445a2b537af6.jpeg")
    no-repeat center;
  background-size: 100% 100%;
}
.qimigninfo {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding: 15px;
  padding-bottom: 0;
}

.infolist {
  padding: 15px 35px;
  li {
    display: flex;
    font-size: 16px;
    line-height: 2.2em;
  }
}

.wbhead {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  line-height: 2.2em;
  text-align: center;
  padding: 15px 0;
}

.memadd {
  padding: 0 15px;
  padding-bottom: 40px;
  .imgbox {
    margin-top: 20px;
  }
}

.botgetbtn {
  margin: 20px auto;
  margin-left: 10%;
  width: 80%;
  font-size: 18px;
  font-weight: bold;
  animation: scaleDrew 1.5s ease-in-out infinite;
}

.copybox {
  background: #ffffff;
  border: 1px solid #04c967;
  border-radius: 4px;
  padding: 8px 20px;
  display: flex;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  .item {
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 1;
    span {
      font-size: 10px;
      color: #1b95d4;
    }
  }

  .fz {
    font-size: 14px;
  }
}

.qrbox {
  display: flex;
  flex-direction: column;
  align-items: center;

  .payitp {
    margin: 20px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #020202;
  }

  .qrcode {
    width: 150px;
  }

  .ttitp {
    font-size: 14px;
  }
}

.kefubox {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    font-size: 14px;
    line-height: 2em;
    span {
      font-size: 14px;
      color: #b95353;
      font-weight: bold;
    }
  }
}
 .shade_box_{
        position: fixed;
        // max-width: 640px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: url('http://cdn1.zhiyileiju.cn/WechatIMG2045.jpeg') no-repeat;
        background-size: 100% 100%;
        z-index: 100;
    }
    .shade_box_content_box_{
        position: fixed;
        max-width: 640px;
        width: 100%;
        // width: 7.5rem;
        top: 50%;
        transform: translate(0,-50%);
        z-index: 100;
        .close_buttom_{
            position: absolute;
            top: -56px;
            width: 30px;
            right: 20px;
            img{
              width: 100%;
            }
        }
        .wbox {
  margin: 0;
  margin-bottom: 0;
}
.successbox {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
  .payicon {
    width: 70px;
    height: 70px;
    margin-top: 30px;
  }
  .payitp {
    font-size: 20px;
    font-weight: 400;
    color: #020202;
    margin-top: 15px;
  }
  .getre {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #fd0101;
    margin-top: 20px;
  }
  .getbtn {
    margin: 20px;
    width: 280px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    animation: scaleDrew 2s ease-in-out infinite;
  }

  .btntip {
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 400;
    color: #020202;
  }
}
.paybj {
  background: url("http://cdn1.zhiyileiju.cn/WechatIMG2048.jpeg")
    no-repeat center;
  background-size: 100% 100%;
}
.qimigninfo {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding: 15px;
  padding-bottom: 0;
}

.infolist {
  padding: 15px 35px;
  li {
    display: flex;
    font-size: 16px;
    line-height: 2.2em;
  }
}
       
       
    }
</style>
