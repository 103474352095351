<template>
    <div class="bg_chunk" :style="`height: ${show?72:100}vh;`">
        <!-- <div class="SucceedPhoneBinding" v-if="isOpen"> -->
        <div class="SucceedPhoneBinding" >
            <div class="content_chunk">
                <h1 class="title">输入手机号立即领取报告</h1>
                <div class="line_chunk"></div>
                <p class="text1_chunk text1">请填写手机号</p>
                <!-- <p class="text2_chunk text1">测算结果</p> -->
                <div class="input_chunk">
                    <span class="phone_text">手机号：</span>
                    <input class="phone_input" v-model="phone_value" type="number" placeholder="请输入您的手机号" @input="inputPhoneExamine" @click="openKeyUp" :autofocus="true" readonly>
                </div>
                <button class="button_chunk animation_scaling" @click="submitButton">立即领取报告</button>
                <!-- <button class="button_img_chunk animation_scaling" @click="submitButton"><img src="https://img.nnabc.cn/202305081515.png" alt=""></button> -->
                <p class="text3_chunk"><i class="icon_chunk"><img src="https://img.nnabc.cn/2023050815191.png" alt=""></i> 隐私协议<span class="color_">《该手机号仅用于免费接收测算报告》</span></p>
                <!-- <p class="text4_chunk" @click="lookOverResult" >直接查看结果报告</p> -->
                <!-- <div class="img_chunk"><img src="https://img.nnabc.cn/202305081519.png" alt=""></div>
                <p class="text5_chunk">如有任何疑问，请致电<span class="color_"><a :href="'tel:' +' 400-968-1585'" rel="external nofollow" >400-968-1685</a></span>联系我们</p> -->
            </div>
            <van-number-keyboard
            v-model="phone_value"
            :show="show"
            :maxlength="11"
            @blur="show = false"
            close-button-text="完成"
            />
        </div>
    </div>
</template>

<script>
import axios from "../../utils/request"
export default {
    data() {
        return {
            phone_value:'',
            isOpen:true,
            show:true

        }
    },
    created () {
    },
    methods:{
        openKeyUp() {
            this.show = true
            console.log('打开键盘')
        },
        inputPhoneExamine(){
            if ( this.phone_value.length > 11 ) {
                this.phone_value = this.phone_value.slice(0,11)
            }
        },
        async submitButton(){
            
            try {
                let data = await axios.post("/order/bindIphone", {
                    order_id: this.$route.params.id,
                    bind_iphone: this.phone_value,
                });
                if (data.data.message == "操作成功") {
                    this.$toast.success("绑定成功");
                    this.$emit('phonePopupSubmit');
                }
            } catch (err) {
                this.$emit('phonePopupSubmit');
            }

            // if ( this.phone_value.length === 11 ) {
            //     let orderBindIphone_data_ = {
            //         order_id: this.$route.params.id,
            //         bind_iphone: this.phone_value
            //     }
            //     $orderBindIphone(orderBindIphone_data_).then( ( res ) => {
            //         this.isOpen = false;
            //         this.$toast.success("绑定成功");
            //         $stat('home_marriage_test_succeed_phone_popup_submit_button')
            //         this.$emit('phonePopupSubmit');
                       
            //     }).catch (()=>{
            //         this.phone_fal = true
            //         this.$toast.fail("绑定失败");
            //     })
            // } else{
            //     this.$dialog({ message: "手机号填写不正确" });
            // }
        },
        lookOverResult(){
            this.$emit('phonePopupSubmit');
        }

    }
}
</script>

<style lang="scss" scoped>
.bg_chunk{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: RGBA(182, 182, 182, 1);
    margin: auto;
    z-index: 111;
    img{
        display: block;
        width: 100%;
    }
    .SucceedPhoneBinding{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background: rgba(0,0,0,.5);
        z-index: 100;
        .content_chunk{
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 50%;
            // left: 50%;
            transform: translate(0,-50%);
            width: 6.16rem;
            padding-top: .47rem;
            padding-bottom: .36rem;
            background: #FFFFFF;
            border-radius: .30rem;
            .title{
                font-size: .40rem;
                color: #000000;
                line-height: .4rem;
                text-align: center;
            }
            .line_chunk{
                width: 100%;
                height: .01rem;
                background: rgba(0, 0, 0, 0.29);
                margin-top: .46rem;
            }
            .text1{
                font-size: .32rem;
                color: #000000;
                line-height: .32rem;
                text-align: center;
            }
            .text1_chunk{
                margin-top: .41rem;
            }
            .text2_chunk{
                margin-top: 0.16rem;
            }
            .text3_chunk{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .18rem;
                color: #000000;
                line-height: .24rem;
                text-align: center;
                margin-top: .2rem;
                .icon_chunk{
                    display: block;
                    width: .24rem;
                    height: .24rem;
                    margin-right: .05rem;
                }
                .color_{
                    color: rgba(0, 0, 0, 0.30);
                }
            }
            .input_chunk{
                display: flex;
                align-items: center;
                width: 5.76rem;
                height: .97rem;
                background: url("https://img.nnabc.cn/202305081509.png") no-repeat;
                background-size: 100% 100%;
                margin: auto;
                margin-top: .4rem;
                font-size: .36rem;
                color: #000000;
                .phone_text{
                    white-space: nowrap;
                    margin-left: .46rem;
                }
                .phone_input{
                    width: 64%;
                    border: none;
                }
            }
            .button_img_chunk{
                display: block;
                width: 4.71rem;
                margin: auto;
                margin-top: .34rem;
            }
            .button_chunk{
                display: block;
                width: 4.71rem;
                height: .8rem;
                line-height: .8rem;
                border-radius: .8rem;
                margin: auto;
                background: red;
                font-size: .5rem;
                color: rgb(255,236,2);
                margin-top: .4rem;
                border: none;
            }
            .text4_chunk{
                font-size: .26rem;
                color: rgba(0,0,0,0.4);
                line-height: .26rem;
                margin-top: .14rem;
                text-align: center;
                text-decoration: underline;
            }
            .img_chunk{
                width: 4.7rem;
                margin: auto;
                margin-top: .5rem;
            }
            .text5_chunk{
                font-size: .24rem;
                color: #000000;
                line-height: .33rem;
                margin-top: .4rem;
                text-align: center;
                .color_{
                    color: #4740B3;
                }
    
            }
        }
        
    }
}
</style>